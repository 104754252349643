(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobxReact"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("OrgTree", ["React", "mobxReact", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["OrgTree"] = factory(require("React"), require("mobxReact"), require("mobx"));
	else
		root["OrgTree"] = factory(root["React"], root["mobxReact"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 